.css-8atqhb {
  width: 80% !important;
  margin-left: 10% !important;
}

.css-qivjh0-MuiStepLabel-label {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  letter-spacing: 0.01em !important;
  font-size: 15px !important;
  color: #100828 !important;
}

/* .css-1bw0nnu-MuiStep-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
} */

.MuiBox-root {
  /* height: 89vh !important; */
  overflow: hidden;
}

.css-z7uhs0-MuiStepConnector-line {
  margin-top: -3px !important;
}

.MuiStepper-alternativeLabel {
  font-size: 10px;
}

/* .table-responsive {
    border-radius: 0px 8px 0px 0px;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 25%) !important;
} */
