@import "/src/resources/styles/color.scss";

.main {
  background-color: $brand-color;
  min-height: 100vh;
  max-height: 100vh;

  .detailsContainer {
    padding: 4.3rem 5.3rem;
    display: flex;
    min-height: 0;
    height: 80%;
    flex-direction: column;
    justify-content: space-between;

    .textContainer {
      .title {
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 5.4rem;
        letter-spacing: 0.01em;
        color: $white;
      }

      .subtitle {
        font-size: 1.3rem;
        line-height: 5.4rem;
        font-weight: 500;
        color: $white;
      }
    }
  }
}
