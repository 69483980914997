@import "/src/resources/styles/color.scss";

.profileImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imageUploadError {
    font-size: 1rem;
    color: $orange-loss;
  }

  .profileImage {
    border-radius: 50%;
    width: 12rem;
    height: 12rem;
    border: 3px solid $brand-color;
    position: relative;

    &.noImage {
      background-color: #ffaa00;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
    }

    .initialText {
      font-size: 6rem;
      font-weight: 700;
      color: $white;
      font-family: "Roboto", sans-serif;
    }

    .editIcon {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 0.5rem;
      border-radius: 50%;
      background-color: $brand-color;
      color: $white;
      text-align: center;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .menu {
      position: absolute;
      display: none;
      // bottom: -10%;
      bottom: 0;
      right: 0;
      margin-bottom: -8rem;
      margin-right: -10rem;
      // margin-left: 10rem;
      z-index: 101;
      cursor: pointer;
      // top: 0;
      // margin-bottom: -5rem;
      min-width: 13rem;
      box-shadow: 0px 1px 4px #00000040;
      background-color: $white;
      font-size: 1.3rem;

      &.show {
        display: flex;
        flex-direction: column;
      }

      .menuItem {
        padding: 1rem 1rem;
        color: $text-gray;
        border-bottom: 1px solid $border-1;
      }
    }

    .fileInput {
      display: none;
    }
  }
}
