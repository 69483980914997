@import "/src/resources/styles/color.scss";

.devPgrsCont {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .Header {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: center;
        background-color: $white;
        width: 100%;
        height: max-content;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 1.5rem;
        h1 {
            font-size: 16px;
            margin: 0;
            color: #212121;
        }
        p {
            color: $text-gray;
            margin: 5px 5px;
        }
        .reviewPencilIcon {
            height: 26px;
            cursor: pointer;
            width: 26px;
            border: 1px solid $text-gray;
            border-radius: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #D5D5D5;;
            // box-shadow: 0px 0px 5px #99A6B7, 0px 0px 13px #ffffff;
            svg  {
                height: 60%;
                color: $text-gray;;
                width: 60%;
            }
        }
    }
    #inputData {
        height: max-content;
        padding: 15px 12px; 
    }
    #reviewInner:nth-child(2), #reviewInner:nth-child(3) {
        padding: 15px 12px;
        height: max-content;
    }
}
