@import "/src/resources/styles/color.scss";

// .main {
//   display: grid;
//   grid-template-columns: 60% 40%;

.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 7rem;

  .confirmSection {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
    gap: 2.2rem;

    .confirmIcon {
      width: 4.2rem;
      height: 4.2rem;
      display: flex;
    }

    .textSection {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 1rem;
      color: $text-gray;
      letter-spacing: 0.01em;

      .title {
        font-size: 2rem;
        font-weight: 600;
      }

      .subtitle {
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
  }
}
// }
