@import "/src/resources/styles/color.scss";

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .backButton {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      background-color: $button-bg-1;
      color: $brand-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      cursor: pointer;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 500;
      color: $text-gray;
    }
  }

  .profileBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nameSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      .name {
        font-size: 2rem;
        font-weight: 600;
        color: #212121;
      }

      .companyName {
        font-size: 1.3rem;
        font-weight: 500;
        color: $text-gray;
      }
    }

    .otherDetailsSection {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2.6rem;

      .detailsContainer {
        display: flex;
        gap: 0.5rem;
        font-size: 1.1rem;
        font-weight: 500;

        .label {
          color: $text-gray;
        }

        .value {
          color: #212121;
        }
      }
    }

    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      button {
        margin-top: 6rem;
        display: inline-block;
        padding: 0.7rem 2.5rem;
        outline: none;
        border: none;
        border-radius: 100px;
        font-size: 1.2rem;
        min-width: 13rem;
        font-weight: 500;
        text-align: center;

        &.outlined {
          color: $brand-color;
          background-color: $white;
          border: 1px solid $brand-color;
        }

        &.filled {
          color: $white;
          background-color: $brand-color;
        }
      }
    }
  }
}
