@import "/src/resources/styles/color.scss";

.btn {
  background-color: $brand-color;
  padding: 1.2rem 1.4rem;
  border-radius: 8px;
  font-size: 1.6rem;
  font-weight: 500;
  outline: none;
  border: none;
  color: $white;
  font-family: "Poppins", sans-serif;
  position: relative;

  &.buttonText {
    transition: all 0.2s;
  }

  &.loading .buttonText {
    visibility: hidden;
    opacity: 0;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &.loading::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 3px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
