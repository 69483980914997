@import "/src/resources/styles/color.scss";

.main {
  display: grid;
  grid-template-columns: 60% 40%;

  @media screen and (max-width: 768px) {
    grid-template-columns: 0% 100%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: 50% 50%;
  }
}
