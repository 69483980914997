@import "/src/resources/styles/color.scss";

.dashContainer {
  display: flex;
  // justify-content: space-between;
  padding: 1rem 2.2rem 1rem 0rem;
  flex-direction: column;
  min-height: 85vh;
  max-height: 85vh;

  .dashContInner {
    display: flex;
    justify-content: space-between;

    .dashTitle {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 16px;
      color: white;
      background: #100828;
      margin-bottom: 0px;
      position: relative;
      z-index: 100;
      border-radius: 10px 10px 0px 0px;
      padding: 1rem 6rem;
      margin-top: 2rem;
      box-shadow: 0px -4px 11px rgb(0 0 0 / 25%) !important;
    }
  }

  .noDataContainer {
    max-height: 72vh;
    min-height: 72vh;
    border-radius: 0px 8px 8px;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    box-shadow: 0px 6px 11px rgb(0 0 0 / 25%) !important;

    .imgContainer {
      display: flex;
      justify-content: center;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      color: $text-gray;
      align-items: center;

      .title {
        font-size: 1.1rem;
        font-weight: 500;
      }

      .subtitle {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .btn {
      display: inline-block;
      border-radius: 10rem;
      padding: 1.1rem 3.2rem;
      font-size: 1.1rem;
      font-weight: 500;
      color: $white;
      background-color: $brand-color;
      border: none;
      outline: none;
    }
  }

  .paginationFooter {
    background-color: #fff;
    padding: 1rem 3.7rem;
    border-top: 2px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    position: relative;
    z-index: 1;
    height: 6rem;
    // box-shadow: 0px 5px 8px rgb(0 0 0 / 25%) !important;
    // border-radius: 0px 0px 8px 8px;

    .paginationArrowIcon {
      color: $brand-color;
      font-size: 1.2rem;
      cursor: pointer;
    }

    .pageList {
      display: flex;
      gap: 1.9rem;

      .pageItem {
        font-size: 1rem;
        color: $brand-color;
        border-radius: 100px;
        min-width: 3.1rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
          background-color: $brand-color;
          color: $white;
        }
      }
    }
  }
}

.tableCont {
  background: white;
  padding: 3.2rem 3.5rem;
  border-radius: 0px 8px 8px;
}

.tableHeader {
  color: #161616;
  font-weight: 500;
  height: 5.5rem;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $bg-color;

  th {
    cursor: pointer;
    font-size: 1.3rem;
    padding: 2rem 1rem !important;
    color: $text-black-16;
    font-weight: 400;
    .rotate {
      transform: rotate(180deg);
    }
  }

  th:first-child {
    padding-left: 2rem !important;
  }
  th:last-child {
    padding: 0.5rem !important;
  }
}
.tableBody {
  // height: 30rem;
  // max-height: 30rem;
  // overflow-y: auto;

  .dip {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 15px;
    color: #efaf00;
  }
  .ic {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 15px;
    color: #37833b;
  }
  .rfr {
    font-size: 1.3rem;
    background: $brand-color;
    border-radius: 4px;
    font-weight: 600;
    padding: 0.6rem 0.8rem;
    color: white;
  }
  #rfrId {
    padding: 1rem 0rem 0rem 1rem !important;
  }

  tr {
    cursor: pointer;
    font-size: 1.3rem;
    // height: 5rem;
    border-bottom: 0.5px solid #dee2e6;
    background-color: white;
  }
  tr:last-child {
    border: none;
  }
  td {
    padding: 1.7rem 1rem !important;
    color: $text-gray;
    font-size: 1.3rem;
    font-weight: 400;
    .rotate {
      transform: rotate(180deg);
    }
  }

  td:first-child {
    padding-left: 2rem !important;
  }
  td:last-child {
    padding: 0.5rem !important;
  }
}

.workspacePopup {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  // background-color: rgb(120 120 120 / 50%);
  right: 0;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    height: 250px;
    opacity: 1;
    z-index: 10000000;
    width: 400px;
    background: #f0f2f8;
    border-radius: 10px;
    svg {
      height: 60px;
      width: 60px;
      color: $brand-color;
      margin: 24px;
    }
    h1 {
      text-align: center;
      margin-bottom: 20px;
      color: $brand-color;
      font-size: 18px;
      font-weight: 600;
    }
    p {
      font-weight: 500;
      color: #99a6b7;
      text-align: center;
      font-size: 14px;
    }
  }
}

.addWsBtn:hover {
  color: $white;
  background-color: $brand-color;
}
.addWsBtn {
  background-color: $white;
  transition: background-color 200ms, color 200ms;
  color: $brand-color;
  border: 1px solid $brand-color;
  font-size: 12px;
  height: 34px;
  border-radius: 30px;
  font-weight: 400;
  padding: 0rem 2.4rem;
  line-height: 2.8rem;
}
.rotate {
  transform: rotate(180deg);
}

.reportTableSearchBar {
  background-color: #f0f2f8;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  padding: 0rem 0.75rem;
  height: 3.5rem;
  position: relative;
  width: 35%;

  .InputLock {
    pointer-events: none;
  }

  input {
    background-color: #f0f2f8;
    border: none;
    font-family: Poppins-medium;
    font-size: 1.6rem;
    outline: none;
    width: 92%;
    height: 100%;
    &::placeholder {
      font-family: Poppins-medium;
      font-style: normal;
      font-size: 1.4rem;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: $text-gray;
    }
  }

  svg {
    color: $brand-color;
    font-size: 1.6rem;
  }
}
