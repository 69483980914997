@import "/src/resources/styles/color.scss";

.modalBodyClass {
    padding: 2rem 4rem 2rem;
}
.colorGrey {
    color: $text-gray;
    justify-content: flex-end;
    svg {
        cursor: pointer;
        color: #b4b4b4;
    }
}
.infoBody {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    h1 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.01em;
        color: $brand-color;
    }
    #title1 {
        margin-top: 13px;
    }
    p {
        width: 90%;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.01em;
        color: $text-black-51
    }
}
.moreOption {
    padding-left: 10px;
    .moreChat {
        position: relative;
        bottom: 0;
        max-height: 200px;
        padding: 0 1.5rem 0.5rem;
        padding-top: 0.5rem;

        a {
            text-decoration: none;
            padding: 0rem 0rem 1rem 1rem;
            border-left: 1px solid $bg-color;
            display: flex;
            color: $text-black-51;

            span {
                margin-left: auto;
                background-color: $brand-color;
                color: $white;
                text-align: center;
                align-items: center;
                border-radius: 50%;
                height: 18px;
                position: relative;
                width: 18px;
            }

            &.hasMessage {
                color: $brand-color;
                font-weight: 600;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    padding: 0.7rem;
    border: 1px solid $border-1;
    color: $text-gray;
    border-radius: 5px;
    cursor: pointer;

    > img {
      width: 0.9rem;
      height: 0.9rem;
    }

    &.active {
      padding: 0.4rem 0.55rem;
      background-color: $brand-color;
      color: $white;
      border: 1px solid $brand-color;
    }

    &:hover {
      border: 1px solid $brand-color;
    }
  }