@import "/src/resources/styles/color.scss";

.header {
  display: flex;
  //   width: 100%;
  align-items: center;
  // border-bottom: 1px solid $border-1;
  // padding-bottom: 0.5rem;
  padding-bottom: 1.5rem;

  .leftContainer {
    display: flex;
    align-items: center;
    flex: 2;
    gap: 0.5rem;

    .workspaceTitle {
      font-size: 2rem;
      color: $brand-color;
      font-weight: 600;
    }

    .iconContainer {
      font-size: 1.6rem;
      cursor: pointer;
      &:hover {
        color: $brand-color;
      }
    }

    .reportDetailsMenu {
      padding: 1rem 1.8rem;
      min-width: max-content;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .menuItem {
        display: flex;
        font-size: 1.1rem;

        .label {
          color: $text-gray;
          margin-right: 0.2rem;
        }

        .value {
          color: #212121;
        }
      }
    }
  }

  .rightContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 1.9rem;

    .calendarContainer {
      min-width: 8rem;
      max-width: 8rem;

      > div {
        width: 100%;
      }
    }

    .outlinedBtn {
      background-color: $brand-color;
      color: #fff;
      outline: none;
      display: inline-block;
      border: 1px solid $border-1;
      border-radius: 5px;
      min-width: 7rem;
      text-align: center;
      padding: 0rem 1.5rem;

      &.blue {
        background: white;
        border: 2px solid $brand-color;
        color: $brand-color;
        font-size: 12px;
        height: 3.4rem;
        font-weight: 500;
        line-height: 2.8rem;
      }

      .btnContent {
        display: flex;
        gap: 0.5rem;
        font-size: 1.4rem;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 1.5em;
        }
        .btnText {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.disable {
  opacity: 0.7;
  cursor: not-allowed !important;
  // pointer-events: none;
}
