@import "/src/resources/styles/color.scss";

.stepLabels {
    width: 17px;
    height: 17px;
    border-radius: 10px;
}

.active {
    background: $brand-color;
}

.upcomming {
    border: 1px solid $brand-color;
}

.labelText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
    color: #596DE7;
}

.reviewAndSubmit {
    height: max-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 3rem 4rem 1rem;
    width: 98%;
    margin-top: 1rem;
    height: 50rem;
}

.formContainer {
    height: max-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 4rem 4rem 2rem;
    width: 80%;
}

.stepperProcess {
    margin-top: 3rem;
    height: 47rem;
}

.NameWorkspace {
    margin-top: 15vh;
}

.stepperCont {
    height: 80vh;
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.stepper {
    top: 87vh;
    position: absolute;
    width: 100%;
}
