@import "/src/resources/styles/color.scss";

.sideBarMain {
  min-width: 22.5rem;
  max-width: 22.5rem;
  position: relative;
  padding: 4.4rem 2.8rem;
  display: flex;

  &.collapsed {
    min-width: 7rem;
    max-width: 7rem;
    padding: 4rem 1rem 0rem 1rem;
    justify-content: center;
    z-index: 80;
  }

  .sideBar {
    max-width: 16rem;
    display: flex;
    flex-direction: column;
    background-color: $white;
    position: fixed;

    .navContainer {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      // transition: 0.5s;

      .navLink {
        display: flex;
        gap: 1.5rem;
        font-size: 1.4rem;
        align-items: center;
        text-decoration: none;
        color: #242331;
        font-weight: 400;

        &.active {
          color: rgb(150, 90, 229);
          // font-weight: 570;
        }

        .icon {
          font-size: 2rem;
        }

        .commingSoon {
          background-color: #F73E77;
          padding: 2px 7px;
          border-radius: 3px;
          font-size: 1rem;
          text-align: center;
          color: $white;
          width: fit-content;
        }

        &.disable {
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
  }

  #openPosition {
    top: 92vh;
    left: 21rem;
  }

  #closePosition {
    top: 92vh;
    left: 6rem;
  }

  .collapsibleContainer {
    position: fixed;
    margin-right: -1rem;
    width: 2.4rem;
    height: 2.4rem;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #596de7;
    font-size: 18px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
  }
}

.backBtn,
.backBtn:hover {
  background: #e4e4e4;
  padding: 5px 15px;
  color: #808080;
  font-size: 1.4rem;
  width: 9rem;
  border-radius: 24px;
  text-decoration: none;

  svg {
    font-size: 1.2rem;
  }
}

.lastUpdateText {
  display: flex;
  font-size: 10px;
  margin-top: 7rem;

  p {
    font-weight: 500;
    font-size: 1.1rem;
    color: #212121;
  }

  span {
    font-weight: 500;
    margin-left: 2px;
    font-size: 1.1rem;
    color: #808080;
  }
}

.reviewSide {
  height: 83vh;
  display: flex;
  flex-direction: column;
}

.reviewSideBar {
  flex: 1;
  display: flex;
  flex-direction: column;

  .metrics {
    margin: 3rem 0rem 1rem;
    flex: 1 1;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      margin-bottom: 1rem;
    }

    #selected {
      background-color: $text-gray;
    }

    .boxInput {
      height: 1.5rem;
      width: 1.5rem;
      border: 1px solid $text-gray;
      margin: 0;
    }

    p {
      margin-left: 1rem;
    }
  }

  h2 {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.3rem;
  }

  p {
    font-size: 1.2rem;
    color: $text-gray;
    margin: 0;
  }

  .workspaceDetail {
    height: 25rem;
    overflow-y: scroll;

    p {
      display: flex;
    }

    b {
      color: $text-gray;
      font-weight: 400;
    }
  }

  ::-webkit-scrollbar-track {
    height: 100px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    margin-right: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 1px;
    background-color: rgba(211, 211, 211, 1);
  }
}

.metricsSearch {
  width: 110%;
  background-color: $bg-color;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 12px;

  input {
    background: transparent;
    width: 90%;
    outline: none !important;
    border: none;
  }
}