@import "/src/resources/styles/color.scss";

.main {
  max-height: 90vh;

  .body {
    margin: 2.5rem 14.3rem;
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 2.6rem 2.6rem;
    height: 100%;
    min-height: 100%;
  }
}
