@import "/src/resources/styles/color.scss";

.contentBox {
  border: none !important;
  border-radius: 15px !important;
}

.body {
  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: none;
  padding: 2rem;
  text-align: center;

  .closeButton {
    // display: flex;
    // justify-content: flex-end;
    text-align: end;
    color: $text-black;
    cursor: pointer;
    font-size: 1.5rem;
  }

  .title {
    font-size: 1.5rem;
    padding-top: 1rem;
    color: $brand-color;
  }
  background-color: $brand-secondary-color;
  .subTitle {
    color: rgba(0, 0, 0, 0.5019607843);
    font-size: 1rem;
    padding-top: 0.5rem;
}

  .buttonBlock {
    padding: 1rem 0rem 0rem;
    display: flex;
    justify-content: space-around;
    .button {
      background-color: $white;
      color: $chat-message;
      font-size: 0.8rem;
      border-radius: 50px;
      border: none;
      min-width: 6rem;

      &.btnOk {
        background-color: $brand-color;
        color: $white;
      }
    }
  }
}
