@import "/src/resources/styles/color.scss";

.main {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  padding: 1rem;
  gap: 1rem;
  // align-items: stretch;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 0.01em;
      color: $text-gray;
    }

    .closeIcon {
      color: $text-gray;
      font-size: 1.6rem;
      cursor: pointer;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    // min-height: 450px;

    .formSection {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 1rem;

      .fileList {
        display: flex;
        flex-direction: column;

        .fileCard {
          display: flex;
          justify-content: space-between;
          padding: 2rem 3rem;
          background-color: #f7f8fe;

          .fileDetails {
            display: flex;
            flex-direction: column;

            .fileName {
              font-size: 1.1rem;
              color: $text-gray;
              font-weight: 500;
            }

            .errorText {
              font-size: 1rem;
              color: $orange-loss;
              font-family: "Roboto", sans-serif;
            }
          }

          .iconContainer {
            display: flex;
            justify-content: flex-end;
            color: $text-gray;
            font-size: 2rem;

            > div {
              cursor: pointer;
            }
          }
        }
      }

      .formError {
        font-size: 1rem;
        color: $orange-loss;
        font-family: "Roboto", sans-serif;
      }

      .intialFileUpload {
        flex: 1;
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0.5px dashed #808080;
        border-radius: 4px;
        min-height: 350px;
        gap: 1.6rem;

        &.uploadError {
          border: 0.5px solid $orange-loss;
        }

        &.intialUploadDone {
          flex-direction: row;
          min-height: 7rem;
          max-height: 7rem;
        }

        > input {
          display: none;
        }

        .uploadText {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            font-size: 1.2rem;
            color: $brand-color;
            font-weight: 500;
          }

          .subtitle {
            font-size: 1rem;
            color: $text-gray;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
          }
        }
      }
    }

    .btnContainer {
      display: flex;
      // margin-bottom: auto;

      justify-content: flex-end;
      gap: 1rem;
      flex: 1;

      button {
        position: relative;

        &.buttonText {
          transition: all 0.2s;
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        &.loading .buttonText {
          visibility: hidden;
          opacity: 0;
        }
      }

      .cancelBtn {
        border-radius: 100px;
        background-color: #f0f2f8;
        color: $chat-message;
        outline: none;
        border: none;
        min-width: 100px;
      }
      .proceedBtn {
        border-radius: 100px;
        background-color: $brand-color;
        border: none;
        color: $white;
        padding: 0.5rem 2rem;
        min-width: 100px;

        &.loading {
          &:disabled {
            opacity: 0.7;
          }
        }

        &.loading::after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          border: 3px solid transparent;
          border-top-color: #ffffff;
          border-radius: 50%;
          animation: button-loading-spinner 1s ease infinite;
        }
      }
    }
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
