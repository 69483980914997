@import "/src/resources/styles/color.scss";

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;
  min-height: 60vh;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .backButton {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      background-color: $button-bg-1;
      color: $brand-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      cursor: pointer;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 500;
      color: $text-gray;
    }
  }
}
