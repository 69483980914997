@import "/src/resources/styles/color.scss";

.cont {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2%;
    h1 {
        font-size: 24px;
        font-weight: 600;
        color: $text-black-21;
    }
    p {
        font-size: 15px;
        font-weight: 500;
        color: $text-gray;
    }
    button:hover {
        background-color: $brand-color;
        color: $white;
    }
    button {
        transition: color 200ms, background-color 300ms;
        outline: none !important;
        background-color: $white;
        border: none !important;
        font-size: 12px;
        border-radius: 50px;
        padding: 7px 20px;
        color: $brand-color;
    }
}