@import "/src/resources/styles/color.scss";

.reviewBody {
  flex-grow: 1;
  height: 100%;
  padding: 3rem 2rem 0rem;
}
.reviewHeader {
  display: flex;
  justify-content: space-between;
  height: 6rem;
  z-index: 10;
  .reviewTabs {
    margin-right: 5rem !important;
    display: flex;
    flex: 1 1;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    gap: 2rem;
    h1 {
      background-color: white;
      padding: 1.2rem 1.8rem;
      border-radius: 5px;
      cursor: pointer;
      color: #808080;
      font-weight: 400;
      font-size: 1.5rem;
      margin: 0;
    }
    #activeTab {
      background-color: $brand-color;
      color: $white;
    }
  }
  .reviewBtns {
    display: flex;
    width: 40rem;
    justify-content: space-evenly;
    align-items: center;
  }
}
.share {
  background-color: $brand-color !important;
  color: $white !important;
}
.addWsBtn {
  background: white;
  border: 2px solid $brand-color;
  color: $brand-color;
  font-size: 12px;
  height: 3.4rem;
  border-radius: 30px;
  font-weight: 500;
  padding: 0rem 2.4rem;
  line-height: 2.8rem;
}

.chartsCont {
  height: calc(100vh - 15rem);
  // padding: 1.5rem 1rem;
  overflow-y: scroll;
  width: 100%;
  padding: 2rem 2rem 2rem 0rem;
  display: flex;
  flex-wrap: wrap;

  .noDataContainer {
    border-radius: 8px;
    max-height: 66vh;
    min-height: 66vh;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    // box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);

    .imgContainer {
      display: flex;
      height: 35%;
      justify-content: center;
      width: 35%;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      color: $text-gray;
      align-items: center;

      .title {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .subtitle {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .btn {
      display: inline-block;
      border-radius: 10rem;
      padding: 1.1rem 3.2rem;
      font-size: 1.1rem;
      font-weight: 500;
      color: $white;
      background-color: $brand-color;
      border: none;
      outline: none;
    }
  }
}

.reviewSideBar {
  flex: 1;
  display: flex;
  flex-direction: column;
  .metrics {
    margin: 3rem 0rem 1rem;
    flex: 1 1;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      margin-bottom: 1rem;
    }
    #selected {
      background-color: $text-gray;
    }
    .boxInput {
      height: 1.5rem;
      width: 1.5rem;
      border: 1px solid $text-gray;
      margin: 0;
    }
    p {
      margin-left: 1rem;
    }
  }
  h2 {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.3rem;
  }
  p {
    font-size: 1.2rem;
    color: $text-gray;
    margin: 0;
  }
  .workspaceDetail {
    height: 25rem;
    overflow-y: scroll;
    p {
      display: flex;
    }
    b {
      color: $text-gray;
      font-weight: 400;
    }
  }
  ::-webkit-scrollbar-track {
    height: 100px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    margin-right: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 1px;
    background-color: rgba(211, 211, 211, 1);
  }
}

.metricsElement {
  display: flex;
  max-height: 25rem;
  overflow-y: scroll;
  flex-direction: column;
}