@import "/src/resources/styles/color.scss";

.header {
  font-size: 1.2rem;
  color: $brand-color;
  font-weight: 600;
  border-bottom: none;
  // padding: 1.5rem 1.5rem 0rem !important;
  .subTitle {
    color: $text-black;
    font-size: 0.8rem;
  }
}

.body {
  padding: 0.5rem 1.5rem 1.5rem !important;
  // padding: 0 !important;
}
