.dropdown-item{
    height: 40px !important;
    font-size: 14px !important;
    color: #808080 !important;;
    text-decoration: none !important;
    border-bottom: 1px solid #F2F2F2 !important;
    padding: 0% 20px !important;
}

.dropdown-item:focus, .dropdown-item.active, .dropdown-item:active {
    background-color: #DFE7F3 !important;
    color: #808080 !important;
}