@import "/src/resources/styles/color.scss";

.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 7rem;

  .resetPasswordSection {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    flex: 1;
    justify-content: center;

    .title {
      font-weight: 600;
      font-size: 3.4rem;
      color: $text-darkblue-1;
    }

    .formSection {
      display: flex;
      flex-direction: column;
      gap: 1.8rem;

      .formError {
        font-size: 1rem;
        color: $orange-loss;
      }
    }
  }
}
