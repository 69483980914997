@import "/src/resources/styles/color.scss";

.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 7rem;
  gap: 6rem;

  .title {
    font-size: 2rem;
    font-weight: 600;
    color: $brand-color;
    letter-spacing: 0.01em;
  }

  .btn {
    display: inline-block;
    border-radius: 8px;
    background-color: $brand-color;
    color: $white;
    font-size: 1.6rem;
    text-align: center;
    padding: 0.9rem 3.9rem;
    border: none;
    outline: none;
  }
}

.stepLabels {
  width: 17px;
  height: 17px;
  border-radius: 10px;
  border: 1px solid $brand-color;
}
