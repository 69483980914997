@import "/src/resources/styles/color.scss";

.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 7rem;

  .backArrow {
    cursor: pointer;
    font-size: 1.8rem;
    color: $text-darkblue-1;
  }

  .signInSection {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    flex: 1;
    justify-content: center;

    .title {
      font-weight: 600;
      font-size: 3.4rem;
      color: $text-darkblue-1;
    }

    .flexField {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .forgotPassword {
        font-size: 1rem;

        text-align: end;
        display: flex;
        justify-content: flex-end;
        color: $brand-color;

        > div {
          color: rgb(150, 90, 229);
          font-weight: 570;
          cursor: pointer;
        }
      }
    }

    .formSection {
      display: flex;
      flex-direction: column;
      gap: 1.8rem;

      .formError {
        font-size: 1rem;
        margin-bottom: -1.5rem;
        color: $orange-loss;
      }
    }
  }

  .bottomTitle {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > div {
      text-align: center;
      font-size: 1.1rem;
      margin-top: auto;
      color: $text-darkblue-1;

      > span {
        color: rgb(150, 90, 229);
        font-weight: 570;
        cursor: pointer;
      }
    }

    .resendVerification {
      color: rgb(150, 90, 229);
      font-weight: 570;
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
}
