@import "/src/resources/styles/color.scss";

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .backButton {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      background-color: $button-bg-1;
      color: $brand-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      cursor: pointer;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 500;
      color: $text-gray;
    }
  }

  .formSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3.8rem;

    .formError {
      font-size: 1rem;
      color: $orange-loss;
    }

    .fieldSection {
      max-width: 34rem;
      min-width: 34rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .profileImageContainer {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .imageUploadError {
          font-size: 1rem;
          color: $orange-loss;
        }
      }
    }

    button {
      //   margin-top: 6rem;
      display: inline-block;
      padding: 0.7rem 2.5rem;
      outline: none;
      border: none;
      border-radius: 100px;
      font-size: 1.2rem;
      min-width: 13rem;
      font-weight: 500;
      text-align: center;
      position: relative;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &.filled {
        color: $white;
        background-color: $brand-color;

        &.loading .buttonText {
          visibility: hidden;
          opacity: 0;
        }

        // &.loading {
        //   &:disabled {
        //     opacity: 0.7;
        //   }
        // }

        .buttonText {
          transition: all 0.2s;
        }

        &.loading::after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          cursor: not-allowed;

          pointer-events: none;
          border: 3px solid transparent;
          border-top-color: #ffffff;
          border-radius: 50%;
          animation: button-loading-spinner 1s ease infinite;
        }
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
