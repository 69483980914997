@import "/src/resources/styles/color.scss";

nav {
  position: sticky;
  width: 100%;
  top: 0;
  min-height: 7rem;
  max-height: 7rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  background-color: #fff;

  .main {
    display: flex;
    justify-content: space-between;
    padding: 1.4rem 2.8rem;

    .brandLogo {
      display: flex;
      align-items: center;
      cursor: pointer;
      max-height: 5rem;

      > img {
        height: 85%;
        object-fit: contain;
      }
    }

    .navbarItem {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .userAvatar {
        width: 3.7rem;
        height: 3.7rem;
        cursor: pointer;
        position: relative;
        border-radius: 50%;
        border: 1px solid $brand-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: #fff;
        background-color: $brand-color;
        font-family: "Roboto", sans-serif;
        position: relative;
      }

      .headerMenuPopover {
        position: absolute;
        margin-bottom: -10rem;
        color: $text-gray;
        display: none;
        bottom: 0;
        z-index: 101;
        background-color: $white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        min-width: 13rem;
        border-radius: 5px;

        &.show {
          display: flex;
          flex-direction: column;
        }

        .menuItem {
          display: flex;
          padding: 1.2rem 6rem 1.2rem 2rem;
          align-items: center;
          gap: 1rem;
          justify-content: flex-start;
          border-bottom: 1px solid #f2f2f2;
          cursor: pointer;
          color: #808080;
          letter-spacing: 0.01rem;

          .icon {
            font-size: 1.7rem;
          }

          .title {
            font-size: 1.5rem;
            font-family: "Roboto", sans-serif;
          }
        }
      }
    }
  }
}
