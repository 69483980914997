@import "/src/resources/styles/color.scss";

.inputContainer {
  display: flex;
  flex-direction: column;

  .inputBox {
    padding: 1rem 1.6rem;
    border: 1px solid $border-c5;
    border-radius: 8px;
    font-size: 1.6rem;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    display: flex;
    width: 100%;

    .showPasswordIcon {
      color: $text-gray;
      cursor: pointer;
    }

    &.disabled {
      background-color: #ececec;
    }

    &.error {
      border: 1px solid $orange-loss;
    }

    > input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 1.6rem;

      &:disabled {
        background-color: #ececec;
      }

      &::placeholder {
        color: $border-1;
      }
    }

    .errorIcon {
      color: $orange-loss;
    }
  }

  .errorMessage {
    font-size: 1rem;
    color: $orange-loss;
  }
}
