@import "/src/resources/styles/color.scss";

.tableWithDropdown {
  background-color: aqua;
}

.dropdownBlock {
  position: relative;
  font-size: 0.8rem;

  .dropdownContent {
    display: none;
    position: absolute;
    max-height: 240px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    overflow-y: auto;
    min-width: 230px;
    border-radius: 10px;
    top: 30px;

    a {
      color: black;
      padding: 8px 16px;
      text-decoration: none;
      display: block;

      input {
        margin-right: 5px;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
    .body {
      position: relative;
      max-height: 230px;
    }

    .footer {
      display: block;
      position: sticky;
      bottom: 0;
      z-index: 101;
      background-color: $brand-color;
      color: $white;
      text-align: center;
      padding: 8px;
      cursor: pointer;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  .dropdownContent {
    display: none;
    position: absolute;
    max-height: 240px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 50;
    overflow-y: auto;
    min-width: 230px;
    border-radius: 10px;

    a {
      color: black;
      padding: 8px 16px;
      text-decoration: none;
      display: block;

      input {
        margin-right: 5px;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
    .body {
      position: relative;
      max-height: 230px;
    }

    .footer {
      display: block;
      position: sticky;
      bottom: 0;
      z-index: 101;
      background-color: $brand-color;
      color: $white;
      text-align: center;
      padding: 8px;
      cursor: pointer;
    }
  }

  &:hover .dropdownContent {
    display: block;
  }
}

// .tableFixHead:hover {
//   background-color: aquamarine;

//   #jk {
//     background-color: aquamarine;
//   }
// }

.tableFixHead {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &.large {
    max-height: 500px;
  }

  &.small {
    max-height: 250px;
  }

  .rotate {
    transform: rotate(180deg);
  }

  table {
    border-collapse: collapse;
    // width: 100%;
    // table-layout: fixed;
    width: 100%;
    // font-size: 0.8rem; for customer facing
    font-size: 1.2rem; // for integration partner

    td {
      padding: 0.5rem;
      white-space: nowrap;
    }

    thead {
      color: $text-black-55;
      font-weight: 600;
      background-color: $bg-color;
      // display: table;
      position: sticky;
      top: 0;
      z-index: 1;
      th {
        padding: 0 0.5rem;
        background: #eee;
        white-space: nowrap;
        border-bottom: 1px solid $white;
        border-right: 1px solid $white;
      }
      th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
      }

      .label {
        background-color: $text-black-4;
        padding: 2px;
        border-radius: 5px;
      }
    }

    tbody {
      color: $text-black-69;
      // display: table;

      td:first-child {
        background-color: inherit;
        position: sticky;
        left: 0;
        z-index: 0;
        border-bottom: 1px solid $bg-color;
      }

      td {
        border-bottom: 1px solid $bg-color;
      }

      tr:nth-child(odd) {
        background-color: $white;
      }
      tr:nth-child(even) {
        background-color: $white-f9;
      }
    }

    tfoot {
      background-color: $brand-secondary-color;
      font-weight: 700;
      color: $text-black-69;

      td {
        position: sticky;
        bottom: 0;
        z-index: 0;
        background-color: $brand-secondary-color;
        color: $brand-color;
      }

      td:first-child {
        color: $text-gray;
        background-color: $brand-secondary-color;
        position: sticky;
        left: 0;
        z-index: 1;
        border-right: 1px solid $bg-color;
      }
    }
  }
}

.chartStyle {
  ul {
    height: 180px !important;
  }
}