@import "/src/resources/styles/color.scss";
.main {
    height: 0rem;
}
.even {
    left: 104%;
}
.odd {
    left: -40%;
}
.body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    z-index: 10000;
    height: max-content;
    border-radius: 8px;
    padding: 15px;
    width: 20rem;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 25%);
    background: white;
    div {
        align-items: flex-end;
        display: flex;
        width: 100%;
        justify-content: space-between;
        svg {
            cursor: pointer;
            font-size: 30px;
            color: $text-gray;
        }
    }
    h1 {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.01em;
        color: $brand-color;
    }
    #title1 {
        margin-top: 8px;
    }
    p {
        width: 90%;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.01em;
        color: $text-black-51
    }
}