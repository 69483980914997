@import "/src/resources/styles/color.scss";

.main {
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  min-height: 85vh;
  gap: 1.7rem;

  .topSection {
    flex: 1;
    // max-height: 15rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: #fff;
    padding: 3rem;

    .messageSection {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      min-height: 0;
      //   gap: 3.5rem;

      .title {
        font-size: 3.6rem;
        font-weight: 600;
        cursor: default;
        color: #100828;
      }

      .subTitle {
        display: flex;
        cursor: default;
        flex-direction: column;

        .question {
          font-size: 1.3rem;
          color: $black;
          font-weight: 500;
          margin-top: -10px;
          margin-bottom: 5px;
        }

        .details {
          font-size: 1.1rem;
          color: $text-gray;
          cursor: default;
          font-weight: 500;
        }
      }
    }

    .greetImageSection {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      //   min-height: 0;
      //   max-height: inherit;

      .imageContainer {
        display: flex;
        align-items: center;
        // max-height: 10rem;
        height: 15rem;

        > img {
          //   max-height: 10rem;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .bottomSection {
    flex: 2;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: #fff;
    padding: 3rem;
    gap: 3rem;

    .title {
      font-size: 1.3rem;
      color: $black;
      font-weight: 500;
    }

    .uploadTypeSection {
      display: flex;
      //   justify-content: space-between;
      gap: 3rem;

      .grid {
        flex: 1;
        border-radius: 5px;
        border: 1px solid $border-1;
        padding: 3.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // gap: 2.3rem;

        .textSection {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 1.3rem;
            color: $black;
            font-weight: 500;
          }

          .subtitle {
            font-size: 1.1rem;
            font-weight: 500;
            color: #808080;
            height: 5rem;
          }
        }
      }
    }

    .buttonContainer {
      text-align: center;

      .buildWorkspaceBtn:hover {
        background-color: $brand-color;
        color: $white;
      }
      .buildWorkspaceBtn {
        background-color: $white;
        transition: background-color 200ms, color 200ms;
        color: $brand-color;
        border: 1px solid $brand-color;
        display: inline-block;
        outline: none;
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
        padding: 0.7rem 1.8rem;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
