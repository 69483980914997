@import "/src/resources/styles/color.scss";

.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 7rem;

  .backArrow {
    cursor: pointer;
    font-size: 1.8rem;
    color: $text-darkblue-1;
  }

  .signUpSection {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .title {
      font-weight: 600;
      font-size: 3.4rem;
      color: $text-darkblue-1;
    }

    .formSection {
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
      .formError {
        font-size: 1rem;
        color: $orange-loss;
      }

      .flexField {
        display: flex;
        gap: 1.5rem;

        @media screen and (max-width: 480px) {
          flex-direction: column;
        }

        @media (min-width: 481px) and (max-width: 630px) {
          flex-direction: column;
        }

        @media (min-width: 769px) and (max-width: 1534px) {
          flex-direction: column;
        }

        > div {
          flex: 1;
        }
      }
    }
  }

  .bottomTitle {
    text-align: center;
    font-size: 1.1rem;
    color: $text-darkblue-1;

    span {
      color: rgb(150, 90, 229);
      font-weight: 570;
      cursor: pointer;
    }
  }
}

.IconUpload {
  cursor: pointer;
  border: 1px solid #c5c5c5;
  padding: 10px;
  border-radius: 8px;
  width: 50%;
  color: $brand-color;
}

.iconError {
  font-size: 10px;
  color: #eb5757;
  margin: 0;
}
#iconErrorBorder {
  border: 1px solid #eb5757;
}