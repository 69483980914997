@import "/src/resources/styles/color.scss";

.activeTiles{
    background: #FFFFFF;
    border: 1.5px solid #596DE7;
    border-radius: 4px;
}

.dataPieceOuter {
    padding: 1.5rem;
}

.checkBorder {
    border: 1px solid #21A366;
}

.suggestionBorder {
    border: 1px solid #FFA600;
}

.rejectBorder {
    border: 1px solid #EB5757;
}

.noneBorder {
    border: 1px solid $white
}

.card {
    background-color: $white;
    padding: 3rem 2rem;
    border-radius: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    > .cardTitle {
        font-size: 1.5rem;
        color: $text-black;
        font-weight: 500;
        // padding: 0 1.5rem;
        letter-spacing: 0.03rem;
        svg {
            cursor: pointer;
            color: #b4b4b4;
            font-weight: 600;
        }
    }

    > .cardTitleBold {
        font-size: 0.9rem;
        color: #333;
        padding: 0 1.5rem;
    }

    .chartOvervirew {
        color: #3f3d56;
        font-size: 0.8rem;
        line-height: 0.8rem;
        font-style: italic;
        padding: 0.5rem 0rem 0.25rem;
        width: fit-content;
        text-align: center;

        .iconBlock {
            background-color: $bg-color;
            display: inline-block;
            height: 24px;
            width: 24px;
            border-radius: 100px;
            text-align: center;
            .icon {
                padding: 5px;
            }
        }

        .profit {
            padding: 0px 4px;
            color: $green-profit;
            background-color: $bg-color;
            margin-left: -2px;

            img {
                padding-right: 0.5rem;
            }
        }

        .loss {
            padding: 0px 4px;
            color: $orange-loss;
            background-color: $bg-color;
            margin-left: -2px;

            img {
                padding-right: 0.5rem;
            }
        }
    }

    > .chartBlock {
        padding: 3rem 1.5rem 1rem ;

        .recentChats {
            position: absolute;
            margin-top: 45px;
            margin-bottom: 1rem;
            top: 0;
            bottom: 0;
            right: -15px;
            .recentChatIcon {
                background: $brand-color;
                border-radius: 50%;
                margin: 1rem 0;
                color: $white;
                height: 30px;
                width: 30px;
                text-align: center;
                box-shadow: 0px 4px 4px 0px #00000040;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                > .deactive {
                    background-color: $bg-color;
                }
            }
        }
        // height: 100%;
    }

    .colorBlack {
        fill: $text-black;
    }

    > .recipientBlock {
        padding-top: 1rem;
        color: #333;
        font-size: 0.9rem;
        display: flex;
        justify-content: space-between;

        p {
            font-size: 0.7rem;
            color: $text-gray;
        }

        .icon {
            padding-top: 2px;
        }
    }

    > .emailBlock {
        padding-top: 1rem;
        color: #666666;
        font-size: 0.8rem;
    }

    .toolBlock {
        font-size: 0.7rem;
        color: $text-gray;
        background-color: $white;
        padding: 0.5rem;
        text-align: start;

        .title {
            font-size: 0.8rem;
            font-weight: 600;
            padding-bottom: 2px;
        }
    }

    .lockIcon {
        .hoverSpan {
            display: none;
        }
        &:hover .hoverSpan {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 5px;
            display: block;
            z-index: 100;
            background: #fff;
            margin: -17px 0px 0px 29px;
            width: 250px;
            position: absolute;
            text-decoration: none;
            font-size: 11px;
            color: rgba(0, 0, 0, 0.5);

            &.left {
                left: 50%;
                margin: -17px 0px 0px 29px;
            }
            &.right {
                right: 50%;
                margin: -17px 29px 0px 0px;
            }
        }
    }
}

.suggestionBox {
    z-index: 1;
    display: flex;
    flex-direction: column;
    // height: 90%;
    margin-bottom: 1rem;
    margin-top: 5%;
    position: absolute;
    right: -11px;
    top: 0px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    .inputField {
        height: 100px;
        font-size: 11px;
        width: 250px;
        border: none !important;
        outline: none !important;
    }
    p {
        color: red;
        margin-bottom: 4px;
        font-size: 10px;
    }
    .disable {
        opacity: 0.7;
        cursor: not-allowed !important;
        // pointer-events: none;
    }
    div {
        display: flex;
        gap: 10px;
    }
    .suggestionButton {
        background-color: #EB5757;
        color: white;
        font-size: 12px;
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 30px;
        margin: 0;
        border-radius: 8px;
    }
    .suggestionButton:nth-child(1) {
        background-color: $brand-color;
        border: 1px solid gray;
    }
}

.actionBtns {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 90%;
    width: 2rem;
    margin-bottom: 1rem;
    margin-top: 5%;
    position: absolute;
    right: -11px;
    top: 0px;
    svg {
        width: 100%;
        cursor: pointer;
        font-size: 2rem;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 4rem;
        background: #DFDFDF;
    }
    .cross {
        margin-bottom: 2.5rem;
        color: #EB5757;
    }
    .check {
        border: 2px solid #21A366;
        border-radius: 20px;
        color: #21A366;
        margin-bottom: 2rem;
    }
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    padding: 0.7rem;
    border: 1px solid $border-1;
    color: $text-gray;
    border-radius: 5px;
    cursor: pointer;

    > img {
      width: 0.9rem;
      height: 0.9rem;
    }

    &.active {
      padding: 0.4rem 0.55rem;
      background-color: $brand-color;
      color: $white;
      border: 1px solid $brand-color;
    }

    &:hover {
      border: 1px solid $brand-color;
    }
  }

  .timePeriod {
    font-weight: 500;
    font-size: 11px;
    margin-top: -5px;
    color: #965AE5;
    letter-spacing: 0.01rem;
  }