@import "/src/resources/styles/color.scss";

.title {
  font-weight: 600;
  font-size: 20px;
  color: $brand-color;
}

.content {
  margin-top: 0px;
  color: #808080;
  font-size: 13px;
  font-family: "Roboto";
  font-family: "Roboto", sans-serif;
}
#invalid {
  border: 1px solid $orange-loss !important;
}
.inputEle::placeholder {
  font-size: 13px;
}
.inputEle,
.inputEle:focus-visible {
  position: relative;
  z-index: 10;
  box-shadow: none !important;
  background: transparent !important;
  height: 40px;
  margin-top: 20px;
  width: 100%;
  border: 1px solid #c5c5c5 !important;
  border-radius: 8px !important;
  outline: none;
  font-size: 14px;
  padding: 0px 12px !important;

  .dropDownToggleEle {
    pointer-events: none;
    display: flex;
    align-items: center;
    height: 100%;
    p {
      font-size: 13px;
      color: #d5d5d5;
      flex-grow: 1;
      margin: 0;
      text-align: left;
    }
    h1 {
      font-size: 14px;
      color: black;
      font-weight: 400;
      // color: #D5D5D5;
      flex-grow: 1;
      margin: 0;
      text-align: left;
    }
    svg {
      color: black;
      height: 15px;
      width: 15px;
    }
  }
}

@mixin BtnProp {
  cursor: pointer;
  height: 40px;
  width: 100px;
  display: flex;
  font-family: "poppins";
  background: $white;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid $brand-color;
  color: $brand-color;
  border-radius: 60px;
  letter-spacing: 1px;
}
#skipBtn {
  color: rgb(150, 90, 229) !important;
  font-weight: 570;
  width: 10%;
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  letter-spacing: 1px;
  cursor: pointer;
  align-items: flex-end;
  background: transparent !important;
  // border: 1px solid #100828;
}
.buttonCont {
  display: flex;
  justify-content: center;
  p {
    margin: 20px;
    @include BtnProp;
  }
  .next {
    background-color: $brand-color;
    color: $white;
    border: none;
  }
}
.buttonContStepsparent{
  display: flex;
  justify-content: space-between;
}
.buttonContSteps {
  display: flex;
  justify-content: end;
  p {
    margin: 20px 0px 0px 10px;
    @include BtnProp;
  }
  .next {
    background-color: $brand-color;
    color: $white;
    border: none;
  }
  // #reviewBtn {
  //     margin: 20px 0px 0px 10px;
  // }
}
.errorMess {
  margin: 4px;
  font-size: 10px;
  color: $orange-loss;
}
::placeholder {
  color: #d5d5d5;
}

@mixin upload {
  cursor: pointer;
  min-height: 25px;
  color: #d5d5d5;
  margin-right: 0;
  width: 100%;
  border: 0.5px dashed #808080;
  border-radius: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  p {
    font-size: 10px;
    text-align: center;
    color: #d5d5d5;
  }
}

.formContInputData {
  display: flex;
  flex-direction: column;
  .innerInput {
    margin-top: 20px;
    display: flex;
    .innerInputDiv {
      padding-right: 0%;
      width: 50%;
      input {
        margin-top: 0px;
      }
      label {
        margin-bottom: 5px;
        font-size: 13px;
        color: #808080;
      }
    }
    .uploadBox {
      display: flex;
      height: 25.5rem;
      flex-direction: column;
      .uploadedBox {
        width: 103%;
        padding-right: 10px;
        max-height: 215px !important;
        overflow: scroll;
        margin: 0;
        .uploaded {
          font-size: 11px;
          display: flex;
          justify-content: space-between;
          margin: 0px 0px 10px 0px;
          width: 100%;
          height: 25px;
          background: #f0eded;
          border: 1px solid #c5c5c5;
          border-radius: 8px;
          padding: 4px 10px;
        }
      }
      .uploadDiagramSmall {
        @include upload;
        flex-direction: row;
      }
      .uploadDiagram {
        @include upload;
        flex-direction: column;

        &.uploadError {
          border: 1px solid $orange-loss;
        }
      }
    }
  }
}

.frequencyDrop {
  height: 40px;
}

.frequencyToggle:focus,
.frequencyToggle:active,
.frequencyToggle:hover {
  background-color: #dfe7f3 !important;
  color: #808080 !important;
}
.frequencyToggle {
  border: none !important;
  border-bottom: 1px solid #f2f2f2 !important;
  box-shadow: none !important;
  background: transparent !important;
  height: 40px !important;
  width: 100%;
  outline: none;
  font-size: 12px;
  border-radius: 0 !important;
  padding: 0px 0px 0px 20px !important;
  .dropDownToggleEle {
    pointer-events: none;
    display: flex;
    align-items: center;
    height: 100%;
    p {
      font-size: 14px;
      color: #808080;
      flex-grow: 1;
      margin: 0;
      text-align: left;
    }
    svg {
      margin-right: 10px;
      color: #808080;
      height: 15px;
      width: 15px;
    }
  }
}
.inputDataDropDown {
  padding: 0 !important;
  width: 100% !important;
}
.weekDropMenu {
  width: 145px !important;
  position: relative !important;
  inset: auto auto 0px 0px;
  transform: translate3d(283px, -160px, 0px) !important;
  padding: 0 !important;
}
.monthDropMenu {
  padding: 30px 20px !important;
  width: 285px !important;
  position: relative !important;
  inset: auto auto 0px 0px;
  transform: translate3d(283px, -160px, 0px) !important;
}

#dropScroll::-webkit-scrollbar-track {
  height: 100px;
}

#dropScroll::-webkit-scrollbar {
  width: 2px;
  margin-right: 10px;
}

#dropScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 1px;
  background-color: rgba(211, 211, 211, 1);
}

.metricsContainer {
  border: 1px solid #c5c5c5 !important;
  border-radius: 8px !important;
  padding: 10px;
  input {
    width: 100%;
  }
  textarea,
  textarea:focus-within {
    overflow: hidden;
    border: none;
    width: 100% !important;
    height: 20px;
    outline: 0;
    font-size: 1.1rem;
    padding: 4px 10px;
  }
}

.addAnother {
  padding: 7px;
  color: $brand-color;
  cursor: pointer;
  font-size: 1.1rem;
}

.displayMetrics {
  margin-bottom: 8px;
  padding: 8px;
  background: #f0eded;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    p {
      font-size: 12px;
      margin-bottom: 5px !important;
      font-weight: 500;
      margin: 0;
    }
    svg {
      height: 14px;
      width: 14px;
      cursor: pointer;
    }
  }
  p {
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 11px;
    color: $text-gray;
    word-wrap: break-word;
  }
}

.metricsOuter {
  overflow: scroll;
  padding-right: 10px;
  position: relative;
  z-index: 100;
  max-height: 285px;
}

.reviewMetricsOuter {
  overflow-y: scroll;
  padding-right: 10px;
  margin-top: 8px;
  padding-bottom: 0;
  flex: 1 1 auto;
  height: 63%;
  max-height: 350px;
}

.flexStart {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  p {
    margin: 0;
    margin-left: 15px;
  }
}

.emptyRadio {
  border: 1px solid #959595;
  border-radius: 1px;
  height: 14px;
  width: 14px;
}
.filledRadio {
  border: 1px solid #959595;
  border-radius: 1px;
  height: 14px;
  width: 14px;
  background-color: #959595;
}

.dataContextDisclaimer {
  color: $brand-color;
  text-align: right;
  margin: 5px 10px;
  font-size: 1rem;
}

.selectedDataSet {
  box-shadow: none !important;
  height: 40px;
  display: flex;
  align-items: center;
  background: #ececec;
  width: 100%;
  border: 1px solid #c5c5c5 !important;
  border-radius: 8px !important;
  outline: none;
  font-size: 14px;
  padding: 0px 12px !important;
}

.reviewNSubmitCont {
  display: flex;
  height: 350px;
  margin-top: 12px;
  flex-direction: row;
  justify-content: space-between;

  label {
    margin-bottom: 5px;
    font-size: 13px;
    color: #808080;
  }
  .reviewInner:nth-child(1) {
    width: 21%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .reviewDataCont:nth-child(1) {
      background: white;
      box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
      width: 100%;
      border-radius: 5px;
    }
    .reviewDataCont {
      padding: 8px 10px;
    }
    .reviewDataCont:nth-child(2) {
      overflow-y: scroll;
      width: 100%;
      height: 78%;
      box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
      border-radius: 8px;
    }
  }
  .reviewInner:nth-child(2),
  .reviewInner:nth-child(3) {
    overflow-y: scroll;
    width: 38%;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    background: white;
    border-radius: 8px;
    padding: 8px 10px;
  }
  .reviewTitle {
    color: $brand-color;
    font-weight: 500;
    font-size: 15px;
    margin: 0px 0px 5px;
  }
  .reviewValue:nth-child(1) {
    color: black;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
  }
  .reviewValue {
    color: black;
    font-size: 12px;
    word-break: break-all;
    font-weight: 500;
    margin: 0px 0px 10px;
  }

  .ReviewtitleCont {
    display: flex;
    justify-content: space-between;
  }

  .reviewPencilIcon {
    height: 25px;
    cursor: pointer;
    width: 25px;
    border: 1px solid white;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px #99a6b7, 0px 0px 13px #ffffff;
    svg {
      height: 60%;
      color: #99a6b7;
      width: 60%;
    }
  }
  .reviewPencilIcon:active {
    box-shadow: 0px 0px 2px #99a6b7, 0px 0px 13px #ffffff;
  }
  label {
    margin-bottom: 0px;
    font-size: 13px;
    color: #808080;
  }
}
.disableNext {
  cursor: not-allowed !important;
  opacity: 0.7;
}
.enableNext {
  cursor: pointer;
  opacity: 1;
}
.dataContextInnerReview {
  display: flex;
  flex-direction: column;
}
