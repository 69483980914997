.react-calendar__tile {
    font-size: 11px !important;
    background-color: #ffffff !important;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 0;
    font-size: 13px;
    max-width: 34px;
    height: 34px;
    border: 1px solid #ffffff;
}
.react-calendar__tile:hover,.react-calendar__tile:focus {
    background: #DFE7F3 !important;
    border-radius: 7px !important;
    outline: none !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #808080;
    pointer-events: none;
}
.react-calendar__month-view__weekdays__weekday {
    margin-bottom: 3%;
}
.react-calendar__month-view__weekdays {
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: #9C9D9F;
    text-align: center;
}
.react-calendar__month-view__days{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 200px;
}
.react-calendar__year-view__months {
    display: block;
}
.react-calendar__year-view {
    display: block;
}
.borderTile {
    border: 2px solid #596DE7 !important;
    border-radius: 7px;
    cursor: pointer;
    line-height: 12px !important;
    pointer-events: all !important;
}
.borderTile:hover, .borderTile:focus {
    border: 2px solid #D5D5D5;
    border-radius: 7px;
    background: #596DE7 !important;
}

.react-calendar {
    width: 238px !important;
}