@import "/src/resources/styles/color.scss";

.icCont {
    display: flex;
    width: 100%;
    height: 100%;
    .childCont1 {
        display: flex;
        width: 50%;
        flex-direction: column;
        padding: 3rem 5rem 0rem;
        justify-content: space-between;
    }
    .icheading {
        font-size: 3.2rem;
        color: $brand-color;
        font-weight: 600;
        line-height: 4.5rem;
    }
    .imgCont {
        padding: 0rem 6rem;
    }
    .childCont1_2:nth-child(1) {
        height: 25rem;
        div {
            margin-bottom: 1.3rem;
            height: 3rem;
            display: flex;
            label {
                color: $text-gray;
                font-weight: 500;
                font-size: 1.4rem;
            }
            p {
                word-break: break-word;
                color: $brand-color;
                font-weight: 500;
                font-size: 1.4rem;
                margin-left: 1rem;
            }
        }
    }
    .childCont1:nth-child(2) {
        padding: 3rem 7rem 1rem 3rem;
    }
    .childCont1_2:nth-child(2) {
        background-color: $white;
        height: 20.5rem;
        padding: 1rem 2rem;
        border: 2px solid $brand-color;
        border-radius: 1.5rem;
        p, li {
            word-break: break-word;
            color: $text-gray;
            font-weight: 500;
            font-size: 1.4rem;
        }
    }
    // .childCont1_2:nth-child(3) {
    //     width: 35%;
    //     cursor: pointer;
    //     background: $brand-color;
    //     height: 3.5rem;
    //     border-radius: 0.5rem;
    //     color: white;
    //     font-weight: 600;
    //     font-size: 1.2rem;
    //     display: flex;
    //     justify-content: space-evenly;
    //     align-items: center;
    //     svg {
    //         font-size: 2rem;
    //     }
    // }
    .childCont1_2:nth-child(3) {
        color: $text-black-b2;
        font-weight: 400;
        font-size: 0.9rem;
    }
}
.chartTitles {
    height: 13rem !important;
    overflow-y: scroll;
}