html {
  font-size: 62.5% !important;
}

body {
  font-size: 1.6rem !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  font-family: "Poppins", sans-serif !important;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 1rem !important;
  }
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* to handle nested modals */
div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
  z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
  z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}

.confirmActionDialog {
  width: 366px;
  height: 265px;
}

::-webkit-scrollbar-track {
  height: 100px;
  z-index: 100000000;
}

::-webkit-scrollbar {
  z-index: 100000000;
  width: 0.5rem;
  margin-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  z-index: 100000000;
  width: 1px;
  background-color: rgb(151, 151, 151);
}

/* Chart Expand View Override */
/* .modal-content {
  padding: 3rem 3rem 4rem !important;
} */
.j-cursor {
  cursor: pointer;
}