@import "/src/resources/styles/color.scss";

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .backButton {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      background-color: $button-bg-1;
      color: $brand-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      cursor: pointer;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 500;
      color: $text-gray;
    }
  }

  .formSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.8rem;

    //  r11

    .fieldSection {
      max-width: 34rem;
      min-width: 34rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }

    button {
      //   margin-top: 6rem;
      display: inline-block;
      padding: 0.7rem 2.5rem;
      outline: none;
      border: none;
      border-radius: 100px;
      font-size: 1.2rem;
      min-width: 13rem;
      font-weight: 500;
      text-align: center;

      &.filled {
        color: $white;
        background-color: $brand-color;
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
}
