$bg-color: #f0eff4;
$bg-color-light: #f0f2f8;
$tab-bg: #f9f9f9;
$brand-secondary-color: #dfe7f3;
$brand-color: #100828;
$input-bg: #c2cfe033;
$notification-bg: #e94f4f;
$border-1: #d5d5d5;
$border-c5: #c5c5c5;
$gray: #90a0b7;
$brand-primary-color: #965ae5;

$text-black-green: #0c3f3f;
$text-black-16: #161616;
$text-black-69: #696b6f;
$text-gray: #808080;
$text-gray-2: #99a6b7;
$text-black-55: #555555;
$text-black-51: #515151;
$text-black-4c: #4c4c4c;
$text-black-21: #212121;
$text-black-d5: #d5d5d5;
$text-black-4: #c4c4c4;
$text-black: #00000080;
$text-black-b1: #dddddd;
$text-black-b2: #8b8b8b;
$text-darkblue-1: #242331;

$black: #161616;
$black-50: rgba(0, 0, 0, 0.5);
$white: #fff;
$white-f9: #f9f9f9;
$green: #21a366;

$pivot-footer: #fbefdf;
$orange-loss: #eb5757;
$green-profit: #1a9c36;

$last-seen: #99a6b7;
$chat-message: #6a6b6d;
$like-button: #dcdcdc;
$unread-status: #fe4b6b;

$input-border: #c2cfe0;

$card-border: #e5edf8;

$button-bg-1: #d9d9d9;
