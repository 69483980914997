@import "/src/resources/styles/color.scss";

.page {
  display: flex;
  flex-grow: 1;
  .pageBody {
    overflow-x: hidden;
    flex: 1 1;
    background-color: #f0f2f8;
    padding: 0rem 3rem 0rem;
 }
}

.mainCont {
  display: flex;
  height: 100vh;
  flex-direction: column;
}